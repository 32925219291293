export default [
  {
    id: 12297,
    to: '09:00:00.000000',
    date: '2022-02-01',
    from: '08:30:00.000000',
    type: 'event',
    state: 'AZ',
    title: 'CN',
    attend: 2,
    lead_id: 60530,
    location: 'ssfdffas',
    lead_name: 'Derrick Simpson',
    real_time: '2022-02-01 08:30:00.000000',
    seller_id: 1,
    creator_id: '1',
    updater_id: null,
    description: null,
    lead_mobile: null,
    seller_name: 'Roger Segura',
    creator_name: 'Roger Segura',
    seller_image: 'https://amgsoftpre.s3.amazonaws.com/USERS/IMG/1/Sett_OriginalCentered.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA6BWVK4LFDQZDOP56%2F20220214%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20220214T212123Z&X-Amz-SignedHeaders=host&X-Amz-Expires=7200&X-Amz-Signature=301a076f690b3510d7ad013878c3b07c3f8647ca2cacc436883514aa6e80c75b',
    updater_name: null,
  },
  {
    id: 12298,
    to: '09:00:00.000000',
    date: '2022-02-01',
    from: '08:30:00.000000',
    type: 'event',
    state: 'AZ',
    title: 'CN',
    attend: 2,
    lead_id: 60530,
    location: 'asd',
    lead_name: 'Derrick Simpson',
    real_time: '2022-02-01 08:30:00.000000',
    seller_id: 1,
    creator_id: '1',
    updater_id: null,
    description: 'asd',
    lead_mobile: null,
    seller_name: 'Roger Segura',
    creator_name: 'Roger Segura',
    seller_image: 'https://amgsoftpre.s3.amazonaws.com/USERS/IMG/1/Sett_OriginalCentered.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA6BWVK4LFDQZDOP56%2F20220214%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20220214T212123Z&X-Amz-SignedHeaders=host&X-Amz-Expires=7200&X-Amz-Signature=301a076f690b3510d7ad013878c3b07c3f8647ca2cacc436883514aa6e80c75b',
    updater_name: null,
  },
  {
    id: 12299,
    to: '13:00:00.000000',
    date: '2022-02-01',
    from: '12:30:00.000000',
    type: 'event',
    state: 'CA',
    title: 'TEL',
    attend: 2,
    lead_id: 60072,
    location: 'ascsda',
    lead_name: 'Martha Cortez',
    real_time: '2022-02-01 12:30:00.000000',
    seller_id: 1,
    creator_id: '1',
    updater_id: null,
    description: 'sdasdasd',
    lead_mobile: null,
    seller_name: 'Roger Segura',
    creator_name: 'Roger Segura',
    seller_image: 'https://amgsoftpre.s3.amazonaws.com/USERS/IMG/1/Sett_OriginalCentered.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA6BWVK4LFDQZDOP56%2F20220214%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20220214T212123Z&X-Amz-SignedHeaders=host&X-Amz-Expires=7200&X-Amz-Signature=301a076f690b3510d7ad013878c3b07c3f8647ca2cacc436883514aa6e80c75b',
    updater_name: null,
  },
  {
    id: 12300,
    to: '14:30:00.000000',
    date: '2022-02-01',
    from: '14:00:00.000000',
    type: 'event',
    state: 'CA',
    title: 'CN',
    attend: 2,
    lead_id: 60072,
    location: 'sefrw',
    lead_name: 'Martha Cortez',
    real_time: '2022-02-01 14:00:00.000000',
    seller_id: 1,
    creator_id: '1',
    updater_id: null,
    description: 'ewrwer',
    lead_mobile: null,
    seller_name: 'Roger Segura',
    creator_name: 'Roger Segura',
    seller_image: 'https://amgsoftpre.s3.amazonaws.com/USERS/IMG/1/Sett_OriginalCentered.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA6BWVK4LFDQZDOP56%2F20220214%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20220214T212123Z&X-Amz-SignedHeaders=host&X-Amz-Expires=7200&X-Amz-Signature=301a076f690b3510d7ad013878c3b07c3f8647ca2cacc436883514aa6e80c75b',
    updater_name: null,
  },
  {
    id: 12301,
    to: '16:00:00.000000',
    date: '2022-02-01',
    from: '15:30:00.000000',
    type: 'event',
    state: 'CA',
    title: 'TEL',
    attend: 2,
    lead_id: 60072,
    location: 'adsawd',
    lead_name: 'Martha Cortez',
    real_time: '2022-02-01 15:30:00.000000',
    seller_id: 1,
    creator_id: '1',
    updater_id: null,
    description: 'asdasd',
    lead_mobile: null,
    seller_name: 'Roger Segura',
    creator_name: 'Roger Segura',
    seller_image: 'https://amgsoftpre.s3.amazonaws.com/USERS/IMG/1/Sett_OriginalCentered.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA6BWVK4LFDQZDOP56%2F20220214%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20220214T212123Z&X-Amz-SignedHeaders=host&X-Amz-Expires=7200&X-Amz-Signature=301a076f690b3510d7ad013878c3b07c3f8647ca2cacc436883514aa6e80c75b',
    updater_name: null,
  },
  {
    id: 12302,
    to: '16:00:00.000000',
    date: '2022-02-01',
    from: '15:30:00.000000',
    type: 'event',
    state: 'CA',
    title: 'CN',
    attend: 2,
    lead_id: 60072,
    location: 'jhvghjk',
    lead_name: 'Martha Cortez',
    real_time: '2022-02-01 15:30:00.000000',
    seller_id: 1,
    creator_id: '1',
    updater_id: null,
    description: null,
    lead_mobile: null,
    seller_name: 'Roger Segura',
    creator_name: 'Roger Segura',
    seller_image: 'https://amgsoftpre.s3.amazonaws.com/USERS/IMG/1/Sett_OriginalCentered.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA6BWVK4LFDQZDOP56%2F20220214%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20220214T212123Z&X-Amz-SignedHeaders=host&X-Amz-Expires=7200&X-Amz-Signature=301a076f690b3510d7ad013878c3b07c3f8647ca2cacc436883514aa6e80c75b',
    updater_name: null,
  },
  {
    id: 12303,
    to: '16:30:00.000000',
    date: '2022-02-01',
    from: '16:00:00.000000',
    type: 'event',
    state: 'CA',
    title: 'TEL',
    attend: 2,
    lead_id: 60072,
    location: 'asd',
    lead_name: 'Martha Cortez',
    real_time: '2022-02-01 16:00:00.000000',
    seller_id: 1,
    creator_id: '1',
    updater_id: null,
    description: 'asdasd',
    lead_mobile: null,
    seller_name: 'Roger Segura',
    creator_name: 'Roger Segura',
    seller_image: 'https://amgsoftpre.s3.amazonaws.com/USERS/IMG/1/Sett_OriginalCentered.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA6BWVK4LFDQZDOP56%2F20220214%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20220214T212123Z&X-Amz-SignedHeaders=host&X-Amz-Expires=7200&X-Amz-Signature=301a076f690b3510d7ad013878c3b07c3f8647ca2cacc436883514aa6e80c75b',
    updater_name: null,
  },
  {
    id: 12304,
    to: '16:30:00.000000',
    date: '2022-02-01',
    from: '16:00:00.000000',
    type: 'event',
    state: 'CA',
    title: 'TEL',
    attend: 2,
    lead_id: 60072,
    location: 'qDEAWS',
    lead_name: 'Martha Cortez',
    real_time: '2022-02-01 16:00:00.000000',
    seller_id: 1,
    creator_id: '1',
    updater_id: null,
    description: 'ASD',
    lead_mobile: null,
    seller_name: 'Roger Segura',
    creator_name: 'Roger Segura',
    seller_image: 'https://amgsoftpre.s3.amazonaws.com/USERS/IMG/1/Sett_OriginalCentered.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA6BWVK4LFDQZDOP56%2F20220214%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20220214T212123Z&X-Amz-SignedHeaders=host&X-Amz-Expires=7200&X-Amz-Signature=301a076f690b3510d7ad013878c3b07c3f8647ca2cacc436883514aa6e80c75b',
    updater_name: null,
  },
  {
    id: 12305,
    to: '16:30:00.000000',
    date: '2022-02-01',
    from: '16:00:00.000000',
    type: 'event',
    state: 'CA',
    title: 'TEL',
    attend: 2,
    lead_id: 60072,
    location: 'SDASD',
    lead_name: 'Martha Cortez',
    real_time: '2022-02-01 16:00:00.000000',
    seller_id: 1,
    creator_id: '1',
    updater_id: null,
    description: 'ASDASD',
    lead_mobile: null,
    seller_name: 'Roger Segura',
    creator_name: 'Roger Segura',
    seller_image: 'https://amgsoftpre.s3.amazonaws.com/USERS/IMG/1/Sett_OriginalCentered.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA6BWVK4LFDQZDOP56%2F20220214%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20220214T212123Z&X-Amz-SignedHeaders=host&X-Amz-Expires=7200&X-Amz-Signature=301a076f690b3510d7ad013878c3b07c3f8647ca2cacc436883514aa6e80c75b',
    updater_name: null,
  },
  {
    id: 12306,
    to: '09:30:00.000000',
    date: '2022-02-08',
    from: '09:00:00.000000',
    type: 'event',
    state: 'CA',
    title: 'TEL',
    attend: 2,
    lead_id: 60979,
    location: 'qweqwe',
    lead_name: 'Nancy Morales',
    real_time: '2022-02-08 09:00:00.000000',
    seller_id: 1,
    creator_id: '1',
    updater_id: null,
    description: null,
    lead_mobile: null,
    seller_name: 'Roger Segura',
    creator_name: 'Roger Segura',
    seller_image: 'https://amgsoftpre.s3.amazonaws.com/USERS/IMG/1/Sett_OriginalCentered.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA6BWVK4LFDQZDOP56%2F20220214%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20220214T212123Z&X-Amz-SignedHeaders=host&X-Amz-Expires=7200&X-Amz-Signature=301a076f690b3510d7ad013878c3b07c3f8647ca2cacc436883514aa6e80c75b',
    updater_name: null,
  },
  {
    id: 12307,
    to: '10:00:00.000000',
    date: '2022-02-08',
    from: '09:30:00.000000',
    type: 'event',
    state: 'CA',
    title: 'TEL',
    attend: 2,
    lead_id: 60979,
    location: 'asd',
    lead_name: 'Nancy Morales',
    real_time: '2022-02-08 09:30:00.000000',
    seller_id: 118,
    creator_id: '118',
    updater_id: null,
    description: 'asdasd',
    lead_mobile: null,
    seller_name: 'Adrian Perez',
    creator_name: 'Adrian Perez',
    seller_image: 'https://amgsoftpre.s3.amazonaws.com/USERS/IMG/118/IMG_1607.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA6BWVK4LFDQZDOP56%2F20220214%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20220214T212123Z&X-Amz-SignedHeaders=host&X-Amz-Expires=7200&X-Amz-Signature=0c1c5041afa16187d4bd6e3eef1f0c3013965380026d1d6676ff5d3d53d7e0b3',
    updater_name: null,
  },
  {
    id: 12308,
    to: '10:00:00.000000',
    date: '2022-02-08',
    from: '09:30:00.000000',
    type: 'event',
    state: 'CA',
    title: 'CN',
    attend: 2,
    lead_id: 60979,
    location: 'ewqe',
    lead_name: 'Nancy Morales',
    real_time: '2022-02-08 09:30:00.000000',
    seller_id: 118,
    creator_id: '118',
    updater_id: null,
    description: 'qweqweqwe',
    lead_mobile: null,
    seller_name: 'Adrian Perez',
    creator_name: 'Adrian Perez',
    seller_image: 'https://amgsoftpre.s3.amazonaws.com/USERS/IMG/118/IMG_1607.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA6BWVK4LFDQZDOP56%2F20220214%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20220214T212123Z&X-Amz-SignedHeaders=host&X-Amz-Expires=7200&X-Amz-Signature=0c1c5041afa16187d4bd6e3eef1f0c3013965380026d1d6676ff5d3d53d7e0b3',
    updater_name: null,
  },
  {
    id: 12309,
    to: '10:30:00.000000',
    date: '2022-02-08',
    from: '10:00:00.000000',
    type: 'event',
    state: 'CA',
    title: 'TEL',
    attend: 2,
    lead_id: 60979,
    location: 'asd',
    lead_name: 'Nancy Morales',
    real_time: '2022-02-08 10:00:00.000000',
    seller_id: 118,
    creator_id: '118',
    updater_id: null,
    description: 'asd',
    lead_mobile: null,
    seller_name: 'Adrian Perez',
    creator_name: 'Adrian Perez',
    seller_image: 'https://amgsoftpre.s3.amazonaws.com/USERS/IMG/118/IMG_1607.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA6BWVK4LFDQZDOP56%2F20220214%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20220214T212123Z&X-Amz-SignedHeaders=host&X-Amz-Expires=7200&X-Amz-Signature=0c1c5041afa16187d4bd6e3eef1f0c3013965380026d1d6676ff5d3d53d7e0b3',
    updater_name: null,
  },
  {
    id: 12310,
    to: '10:30:00.000000',
    date: '2022-02-08',
    from: '10:00:00.000000',
    type: 'event',
    state: 'CA',
    title: 'CN',
    attend: 2,
    lead_id: 60979,
    location: 'wer',
    lead_name: 'Nancy Morales',
    real_time: '2022-02-08 10:00:00.000000',
    seller_id: 118,
    creator_id: '118',
    updater_id: null,
    description: 'qwr',
    lead_mobile: null,
    seller_name: 'Adrian Perez',
    creator_name: 'Adrian Perez',
    seller_image: 'https://amgsoftpre.s3.amazonaws.com/USERS/IMG/118/IMG_1607.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA6BWVK4LFDQZDOP56%2F20220214%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20220214T212123Z&X-Amz-SignedHeaders=host&X-Amz-Expires=7200&X-Amz-Signature=0c1c5041afa16187d4bd6e3eef1f0c3013965380026d1d6676ff5d3d53d7e0b3',
    updater_name: null,
  },
  {
    id: 12311,
    to: '10:30:00.000000',
    date: '2022-02-08',
    from: '10:00:00.000000',
    type: 'event',
    state: 'CA',
    title: 'CN',
    attend: 2,
    lead_id: 60979,
    location: 'qrfasf',
    lead_name: 'Nancy Morales',
    real_time: '2022-02-08 10:00:00.000000',
    seller_id: 118,
    creator_id: '118',
    updater_id: null,
    description: 'sadfasfasf',
    lead_mobile: null,
    seller_name: 'Adrian Perez',
    creator_name: 'Adrian Perez',
    seller_image: 'https://amgsoftpre.s3.amazonaws.com/USERS/IMG/118/IMG_1607.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA6BWVK4LFDQZDOP56%2F20220214%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20220214T212123Z&X-Amz-SignedHeaders=host&X-Amz-Expires=7200&X-Amz-Signature=0c1c5041afa16187d4bd6e3eef1f0c3013965380026d1d6676ff5d3d53d7e0b3',
    updater_name: null,
  },
  {
    id: 12312,
    to: '12:00:00.000000',
    date: '2022-02-08',
    from: '11:30:00.000000',
    type: 'event',
    state: 'CA',
    title: 'CN',
    attend: 2,
    lead_id: 60979,
    location: 'asdas',
    lead_name: 'Nancy Morales',
    real_time: '2022-02-08 11:30:00.000000',
    seller_id: 118,
    creator_id: '118',
    updater_id: null,
    description: 'sadsad',
    lead_mobile: null,
    seller_name: 'Adrian Perez',
    creator_name: 'Adrian Perez',
    seller_image: 'https://amgsoftpre.s3.amazonaws.com/USERS/IMG/118/IMG_1607.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA6BWVK4LFDQZDOP56%2F20220214%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20220214T212123Z&X-Amz-SignedHeaders=host&X-Amz-Expires=7200&X-Amz-Signature=0c1c5041afa16187d4bd6e3eef1f0c3013965380026d1d6676ff5d3d53d7e0b3',
    updater_name: null,
  },
  {
    id: 12313,
    to: '12:00:00.000000',
    date: '2022-02-08',
    from: '11:30:00.000000',
    type: 'event',
    state: 'CA',
    title: 'TEL',
    attend: 2,
    lead_id: 60979,
    location: 'asd',
    lead_name: 'Nancy Morales',
    real_time: '2022-02-08 11:30:00.000000',
    seller_id: 118,
    creator_id: '118',
    updater_id: null,
    description: 'asdasd',
    lead_mobile: null,
    seller_name: 'Adrian Perez',
    creator_name: 'Adrian Perez',
    seller_image: 'https://amgsoftpre.s3.amazonaws.com/USERS/IMG/118/IMG_1607.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA6BWVK4LFDQZDOP56%2F20220214%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20220214T212123Z&X-Amz-SignedHeaders=host&X-Amz-Expires=7200&X-Amz-Signature=0c1c5041afa16187d4bd6e3eef1f0c3013965380026d1d6676ff5d3d53d7e0b3',
    updater_name: null,
  },
  {
    id: 12314,
    to: '13:00:00.000000',
    date: '2022-02-08',
    from: '12:30:00.000000',
    type: 'event',
    state: 'CA',
    title: 'CN',
    attend: 2,
    lead_id: 60979,
    location: 'lkjhgfcvbghjk',
    lead_name: 'Nancy Morales',
    real_time: '2022-02-08 12:30:00.000000',
    seller_id: 1,
    creator_id: '1',
    updater_id: null,
    description: 'lkjhgfghj',
    lead_mobile: null,
    seller_name: 'Roger Segura',
    creator_name: 'Roger Segura',
    seller_image: 'https://amgsoftpre.s3.amazonaws.com/USERS/IMG/1/Sett_OriginalCentered.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA6BWVK4LFDQZDOP56%2F20220214%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20220214T212123Z&X-Amz-SignedHeaders=host&X-Amz-Expires=7200&X-Amz-Signature=301a076f690b3510d7ad013878c3b07c3f8647ca2cacc436883514aa6e80c75b',
    updater_name: null,
  },
  {
    id: 12315,
    to: '12:30:00.000000',
    date: '2022-02-09',
    from: '12:00:00.000000',
    type: 'event',
    state: 'CA',
    title: 'TEL',
    attend: 2,
    lead_id: 60979,
    location: 'dasdasd',
    lead_name: 'Nancy Morales',
    real_time: '2022-02-09 12:00:00.000000',
    seller_id: 1,
    creator_id: '1',
    updater_id: null,
    description: 'asd',
    lead_mobile: null,
    seller_name: 'Roger Segura',
    creator_name: 'Roger Segura',
    seller_image: 'https://amgsoftpre.s3.amazonaws.com/USERS/IMG/1/Sett_OriginalCentered.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA6BWVK4LFDQZDOP56%2F20220214%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20220214T212123Z&X-Amz-SignedHeaders=host&X-Amz-Expires=7200&X-Amz-Signature=301a076f690b3510d7ad013878c3b07c3f8647ca2cacc436883514aa6e80c75b',
    updater_name: null,
  },
  {
    id: 12316,
    to: '12:30:00.000000',
    date: '2022-02-09',
    from: '12:00:00.000000',
    type: 'event',
    state: 'CA',
    title: 'TEL',
    attend: 2,
    lead_id: 60979,
    location: 'asdas',
    lead_name: 'Nancy Morales',
    real_time: '2022-02-09 12:00:00.000000',
    seller_id: 1,
    creator_id: '1',
    updater_id: null,
    description: 'asd',
    lead_mobile: null,
    seller_name: 'Roger Segura',
    creator_name: 'Roger Segura',
    seller_image: 'https://amgsoftpre.s3.amazonaws.com/USERS/IMG/1/Sett_OriginalCentered.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA6BWVK4LFDQZDOP56%2F20220214%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20220214T212123Z&X-Amz-SignedHeaders=host&X-Amz-Expires=7200&X-Amz-Signature=301a076f690b3510d7ad013878c3b07c3f8647ca2cacc436883514aa6e80c75b',
    updater_name: null,
  },
  {
    id: 12317,
    to: '12:30:00.000000',
    date: '2022-02-09',
    from: '12:00:00.000000',
    type: 'event',
    state: 'CA',
    title: 'CN',
    attend: 2,
    lead_id: 60979,
    location: 'dsad',
    lead_name: 'Nancy Morales',
    real_time: '2022-02-09 12:00:00.000000',
    seller_id: 1,
    creator_id: '1',
    updater_id: null,
    description: 'asdasd',
    lead_mobile: null,
    seller_name: 'Roger Segura',
    creator_name: 'Roger Segura',
    seller_image: 'https://amgsoftpre.s3.amazonaws.com/USERS/IMG/1/Sett_OriginalCentered.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA6BWVK4LFDQZDOP56%2F20220214%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20220214T212123Z&X-Amz-SignedHeaders=host&X-Amz-Expires=7200&X-Amz-Signature=301a076f690b3510d7ad013878c3b07c3f8647ca2cacc436883514aa6e80c75b',
    updater_name: null,
  },
  {
    id: 12318,
    to: '12:30:00.000000',
    date: '2022-02-09',
    from: '12:00:00.000000',
    type: 'event',
    state: 'CA',
    title: 'TEL',
    attend: 2,
    lead_id: 60979,
    location: 'SDsA',
    lead_name: 'Nancy Morales',
    real_time: '2022-02-09 12:00:00.000000',
    seller_id: 1,
    creator_id: '1',
    updater_id: null,
    description: 'AADAS',
    lead_mobile: null,
    seller_name: 'Roger Segura',
    creator_name: 'Roger Segura',
    seller_image: 'https://amgsoftpre.s3.amazonaws.com/USERS/IMG/1/Sett_OriginalCentered.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA6BWVK4LFDQZDOP56%2F20220214%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20220214T212123Z&X-Amz-SignedHeaders=host&X-Amz-Expires=7200&X-Amz-Signature=301a076f690b3510d7ad013878c3b07c3f8647ca2cacc436883514aa6e80c75b',
    updater_name: null,
  },
]
